import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiFillMedicineBox } from 'react-icons/ai';
import { BsPeopleFill } from 'react-icons/bs';
import { IoPersonAdd } from 'react-icons/io5';
import { MdOutlineMessage } from 'react-icons/md';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { RiToolsFill } from 'react-icons/ri';
import { GiMedicines } from 'react-icons/gi';
import { BiSolidCoupon } from 'react-icons/bi';
import { TfiWrite } from 'react-icons/tfi';
import { RiLogoutCircleLine } from 'react-icons/ri';
import { MdOutlineDiscount } from 'react-icons/md';
import { IoCreate } from 'react-icons/io5';
import { FaUserGroup } from 'react-icons/fa6';
import { FaUpload } from 'react-icons/fa6';

const LeftSection = ({ selectedOption, handleOptionClick }) => {
  const [logoutMessage, setLogoutMessage] = useState('');

  const handleLogout = () => {
    // Clear the admin token from localStorage
    localStorage.removeItem('adminToken');
    // Set the logout message
    setLogoutMessage('Logout Successful!');
    // Optionally, redirect to the home page or another page after a short delay
    setTimeout(() => {
      // Add your redirect logic here, e.g., using navigate from react-router
    }, 2000);
  };

  return (
    <div className="w-1/6 bg-gradient-to-b from-orange-300 to-orange-600 p-4">
      <h2 className="text-lg font-bold mb-4">Options</h2>
      {logoutMessage && (
        <div className="mb-4 text-green-500 font-bold">{logoutMessage}</div>
      )}
      <ul className="text-[18px]">
        <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/products"
            className={`${selectedOption === 'products' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('products')}
          >
            <GiMedicines /> &nbsp; All Product
          </Link>
        </li>

        <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/addproduct"
            className={`${selectedOption === 'addproducts' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('addproducts')}
          >
            <AiFillMedicineBox /> &nbsp; Add Product
          </Link>
        </li>

        <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/coupons"
            className={`${selectedOption === 'coupons' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('coupons')}
          >
            <BiSolidCoupon /> &nbsp; Coupons
          </Link>
        </li>

        <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/blogs"
            className={`${selectedOption === 'blogs' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('blogs')}
          >
            <TfiWrite /> &nbsp; Blogs
          </Link>
        </li>

        <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/users"
            className={`${selectedOption === 'about' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('about')}
          >
            <BsPeopleFill /> &nbsp; Users
          </Link>
        </li>

        <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/queries"
            className={`${selectedOption === 'queries' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('queries')}
          >
            <MdOutlineMessage /> &nbsp; Help Queries
          </Link>
        </li>

        <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/vendor-requests"
            className={`${selectedOption === 'vendor-requests' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('vendor-requests')}
          >
            <IoPersonAdd /> &nbsp; Vendor Requests
          </Link>
        </li>

        <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/allvendors"
            className={`${selectedOption === 'allvendors' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('allvendors')}
          >
            <IoPersonAdd /> &nbsp; All Vendors
          </Link>
        </li>

        <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/faq"
            className={`${selectedOption === 'faq' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('faq')}
          >
            <BsQuestionCircleFill /> &nbsp; FAQs
          </Link>
        </li>

        <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/testimonial"
            className={`${selectedOption === 'testimonial' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('testimonial')}
          >
            <RiToolsFill /> &nbsp; Testimonials
          </Link>
        </li>

        <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/diffqueries"
            className={`${selectedOption === 'queries' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('diffqueries')}
          >
            <MdOutlineMessage /> &nbsp; Queries
          </Link>
        </li>

        <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/allsmartdeals"
            className={`${selectedOption === 'queries' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('allsmartdeals')}
          >
            <MdOutlineDiscount /> &nbsp; Smart Deals
          </Link>
        </li>

        <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/createsmartdeal"
            className={`${selectedOption === 'queries' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('allsmartdeals')}
          >
            <IoCreate /> &nbsp; Create Deal
          </Link>
        </li>

        <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/alliances"
            className={`${selectedOption === 'queries' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('alliances')}
          >
            <FaUserGroup /> &nbsp; Alliances
          </Link>
        </li>

        <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/uploadproducts"
            className={`${selectedOption === 'queries' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('uploadProducts')}
          >
            <FaUpload /> &nbsp; Upload Products
          </Link>
        </li>

        <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/"
            className={`${selectedOption === 'logout' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={handleLogout} // Call handleLogout function
          >
            <RiLogoutCircleLine /> &nbsp; Logout
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default LeftSection;
