import React, { useState, useEffect } from 'react';
import axiosClient from '../components/AxiosClient'; // Assuming this is where your Axios client is configured

const AllVendors = () => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVendors = async () => {
      setLoading(true);
      setError(null);

      try {
        // Fetch adminToken from localStorage
        const adminToken = localStorage.getItem('adminToken');

        // Axios POST request configuration
        const config = {
          headers: {
            Authorization: `Bearer ${adminToken}`, // Assuming your API expects Bearer token
          },
        };

        const response = await axiosClient.post('/vendors/get_all_vendors', null, config);
        console.log('Vendors API response:', response.data);
        setVendors(response.data.vendors);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching vendors:', error);
        setError('Failed to fetch vendors');
        setLoading(false);
      }
    };

    fetchVendors();
  }, []);

  return (
    <div className="p-5">
      <h1 className="text-3xl mb-5">All Vendors</h1>
      {loading ? (
        <p>Loading vendors...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : vendors.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-center">Vendor ID</th>
                <th className="py-2 px-4 border-b text-center">Vendor Name</th>
                <th className="py-2 px-4 border-b text-center">Mobile</th>
                <th className="py-2 px-4 border-b text-center">Email</th>
                <th className="py-2 px-4 border-b text-center">Pharmacy Address</th>
                <th className="py-2 px-4 border-b text-center">Pharmacy City</th>
                <th className="py-2 px-4 border-b text-center">Pharmacy Count</th>
                <th className="py-2 px-4 border-b text-center">Latitude</th>
                <th className="py-2 px-4 border-b text-center">Longitude</th>
                <th className="py-2 px-4 border-b text-center">Active</th>
                <th className="py-2 px-4 border-b text-center">Payment Status</th>
                <th className="py-2 px-4 border-b text-center">Payment Date</th>
              </tr>
            </thead>
            <tbody>
              {vendors.map((vendor) => (
                <tr key={vendor.vendor_id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b text-center">{vendor.vendor_id}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.vendor_name}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.phone}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.email}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.pharmacy_address}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.city}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.pharmacy_count}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.latitude}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.longitude}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.active ? 'Active' : 'Inactive'}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.payment_status}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.payment_date}</td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No vendors found.</p>
      )}
    </div>
  );
};

export default AllVendors;
