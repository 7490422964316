import React, { useEffect, useState } from 'react';
import axiosClient from '../components/AxiosClient';

const AlliancesPage = () => {
  const [alliances, setAlliances] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [totalPages, setTotalPages] = useState(1); // Track total pages for pagination
  const [deletingAllianceId, setDeletingAllianceId] = useState(null);
  const [selectedAlliance, setSelectedAlliance] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  // Fetch alliances from the API
  const fetchAlliances = async () => {
    try {
      const response = await axiosClient.post(`/alliances/all/${sortOrder}/${currentPage}/${10}`, {});
      setAlliances(response.data.data[0].alliances);
      setTotalPages(response.data.data[0].total_pages); // Assume total_pages is returned in the response
    } catch (error) {
      console.error("Error fetching alliances", error);
    }
  };

  // Fetch alliances whenever sortOrder or currentPage changes
  useEffect(() => {
    fetchAlliances();
  }, [sortOrder, currentPage]);

  // Handle sorting
  const handleSort = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  // Handle deleting an alliance
  const handleDelete = async (id) => {
    try {
      await axiosClient.post(`/alliances/delete_alliance/${id}`);
      setAlliances(alliances.filter(a => a._id !== id)); // Remove the deleted alliance
      setShowPopup(false);
    } catch (error) {
      console.error("Error deleting alliance", error);
    }
  };

  // Confirm delete popup
  const confirmDelete = (id) => {
    setDeletingAllianceId(id);
    setShowPopup(true);
  };

  // Handle viewing alliance details
  const handleViewDetails = async (id) => {
    try {
      const response = await axiosClient.post(`/alliances/search/${id}`);
      setSelectedAlliance(response.data.data[0]); // Store the alliance details
      setShowDetailsPopup(true); // Open the details popup
    } catch (error) {
      console.error("Error fetching alliance details", error);
    }
  };

  // Handle pagination
  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  return (
    <div>
      <h1>Alliances</h1>
      <button className="sort-button" onClick={handleSort}>
        Sort {sortOrder === 'asc' ? 'Descending' : 'Ascending'}
      </button>

      <div className="alliances-list">
        {alliances.map(alliance => (
          <div key={alliance._id} className="alliance-item">
            <span>{alliance.name} - {alliance.company_name}</span>
            <button className="details-button" onClick={() => handleViewDetails(alliance._id)}>Details</button>
            <button className="delete-button" onClick={() => confirmDelete(alliance._id)}>Delete</button>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="pagination">
        <button
          className="page-button"
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          className="page-button"
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      {showPopup && (
        <div className="popup">
          <p>Are you sure you want to delete this alliance?</p>
          <div className="popup-buttons">
            <button className="confirm-button" onClick={() => handleDelete(deletingAllianceId)}>Yes</button>
            <button className="cancel-button" onClick={() => setShowPopup(false)}>No</button>
          </div>
        </div>
      )}

      {showDetailsPopup && selectedAlliance && (
        <div className="popup">
          <h3>Alliance Details</h3>
          <p><strong>Name:</strong> {selectedAlliance.name}</p>
          <p><strong>Company:</strong> {selectedAlliance.company_name}</p>
          <p><strong>Email:</strong> {selectedAlliance.email}</p>
          <p><strong>Mobile:</strong> {selectedAlliance.mobile}</p>
          <p><strong>Address:</strong> {selectedAlliance.buisness_address}</p>
          <p><strong>Message:</strong> {selectedAlliance.message}</p>
          <p><strong>How did you hear about us:</strong> {selectedAlliance.how_did_you_hear_about_us}</p>
          <button onClick={() => setShowDetailsPopup(false)}>Close</button>
        </div>
      )}

      <style jsx>{`
        .sort-button, .details-button, .delete-button, .page-button, .confirm-button, .cancel-button {
          padding: 10px 15px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 14px;
          transition: background-color 0.3s ease, transform 0.2s ease;
        }

        .sort-button {
          background-color: #007bff;
          color: white;
        }

        .sort-button:hover {
          background-color: #0056b3;
          transform: scale(1.05);
        }

        .details-button {
          background-color: #17a2b8;
          color: white;
        }

        .details-button:hover {
          background-color: #138496;
          transform: scale(1.05);
        }

        .delete-button {
          background-color: #dc3545;
          color: white;
        }

        .delete-button:hover {
          background-color: #c82333;
          transform: scale(1.05);
        }

        .page-button {
          background-color: #6c757d;
          color: white;
          margin: 0 10px;
        }

        .page-button:disabled {
          background-color: #adb5bd;
          cursor: not-allowed;
        }

        .popup {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: white;
          padding: 20px;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          z-index: 1000;
        }

        .popup-buttons {
          display: flex;
          justify-content: space-between;
        }

        .alliances-list {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        .alliance-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #f8f9fa;
          padding: 10px;
          border-radius: 5px;
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
        }

        .alliance-item:hover {
          background-color: #e9ecef;
        }

        .pagination {
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </div>
  );
};

export default AlliancesPage;
